import React, { useState, useEffect } from "react"
import { Input } from "@rebass/forms"
import { Text, Flex, Box, Button } from "rebass"
import Selector from "./Selector"
import omit from "lodash.omit"
import uniqBy from "lodash.uniqby"
import TabCloseButton from "./icons/Remove"
import Editor from "react-simple-code-editor"
import { highlight, languages } from "prismjs"
import { CopyToClipboard } from "react-copy-to-clipboard"
import "prismjs/components/prism-clike"
import "prismjs/components/prism-javascript"

const regions = [
  "us-east-1",
  "eu-north-1",
  "ap-south-1",
  "eu-west-3",
  "eu-west-2",
  "eu-west-1",
  "ap-northeast-2",
  "ap-northeast-1",
  "sa-east-1",
  "ca-central-1",
  "ap-southeast-1",
  "ap-southeast-2",
  "eu-central-1",
  "us-east-2",
  "us-west-1",
  "us-west-2",
]

const keyAttributeTypes = ["String", "Number", "Binary"]

type BillingMode = "On-Demand" | "Provisioned"
type KeyType = "HASH" | "RANGE"
interface Projection {
  ProjectionType: ProjectionType
}
type ProjectionType = "ALL" | "KEYS_ONLY" | "INCLUDE"
interface KeyElement {
  AttributeName: string
  KeyType: KeyType
}
interface AttributeDefinition {
  AttributeName: string
  AttributeType: string // S B N NS SS BS
}
interface SecondaryIndex {
  id: string
  IndexName: string
  KeySchema: KeyElement[]
  Projection: Projection
}
interface Tag {
  Key: string
  Value: string
  id: string
}

const NewTable: React.FC = () => {
  const [isInProgress, setInProgress] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()

  const [rcu, setRcu] = useState<number>(10)
  const [wcu, setWru] = useState<number>(10)
  const [region, setRegion] = useState("us-east-1")
  const [keyType, changeKeyType] = useState("Simple")
  const [tableName, setTablename] = useState("")
  const [billingMode, setBillingMode] = useState<BillingMode>("On-Demand")
  const [tags, setTags] = useState<Tag[]>([])
  const [keySchema, setKeySchema] = useState<KeyElement[]>([
    {
      KeyType: "HASH",
      AttributeName: "id",
    },
    {
      KeyType: "RANGE",
      AttributeName: "",
    },
  ])
  const [attributeDefinitions, setAttributeDefinitions] = useState<
    AttributeDefinition[]
  >([
    {
      AttributeName: "id",
      AttributeType: "S",
    },
  ])
  const [gsis, setGsis] = useState<SecondaryIndex[]>([])

  const changeBillingMode = (billingMode: string) => {
    if (billingMode === "On-Demand") {
      setBillingMode("On-Demand")
    } else {
      setBillingMode("Provisioned")
    }
  }

  const setPartitionKey = (value: string) => {
    setKeySchema((keySchema: KeyElement[]) => [
      {
        KeyType: "HASH" as KeyType,
        AttributeName: value,
      },
      keySchema[1],
    ])
  }

  const setSortKey = (value: string) => {
    setKeySchema((keySchema: KeyElement[]) => [
      keySchema[0],
      {
        KeyType: "RANGE" as KeyType,
        AttributeName: value,
      },
    ])
  }

  const addOrChangeAttributeType = (
    AttributeName: string,
    AttributeType: string
  ) => {
    const otherAttributeDefinitions = attributeDefinitions.filter(
      a => a.AttributeName !== AttributeName
    )

    setAttributeDefinitions(
      uniqBy(
        [
          {
            AttributeName,
            AttributeType: AttributeType.charAt(0),
          },
          ...otherAttributeDefinitions,
        ],
        "AttributeName"
      )
    )
  }

  const removeAttributeType = (AttributeName: string) => {
    const otherAttributeDefinitions = attributeDefinitions.filter(
      a => a.AttributeName !== AttributeName
    )

    setAttributeDefinitions([...otherAttributeDefinitions])
  }

  const changeAttributeTypeName = (current: string, newName: string) => {
    console.log("Changing", {
      current,
      newName,
    })
    const otherAttributeDefinitions = attributeDefinitions.filter(
      a => a.AttributeName !== current
    )
    const curr = attributeDefinitions.find(a => a.AttributeName === current)

    console.log(curr, attributeDefinitions)

    setAttributeDefinitions(
      uniqBy(
        [
          ...otherAttributeDefinitions,
          {
            AttributeName: newName,
            AttributeType: curr ? curr.AttributeType : "S",
          },
        ],
        "AttributeName"
      )
    )
  }

  const addGlobalSecondaryIndex = () => {
    setGsis([
      ...gsis,
      {
        id: String(+new Date()),
        IndexName: `gsi_${gsis.length}`,
        Projection: {
          ProjectionType: "ALL",
        },
        KeySchema: [
          {
            AttributeName: "",
            KeyType: "HASH",
          },
          {
            AttributeName: "",
            KeyType: "RANGE",
          },
        ],
      },
    ])
  }

  const addTag = () => {
    setTags((tags: Tag[]) => [
      ...tags,
      { Key: "", Value: "", id: String(+new Date()) },
    ])
  }

  const removeTag = (tag: Tag) => {
    setTags((tags: Tag[]) => tags.filter(t => t.id !== tag.id))
  }

  const changeTag = (id: string, Name: string, Value: string) => {
    const changedTag = tags.find(t => t.id === id)!
    const otherTags = tags.filter(t => t.id !== id)

    changedTag.Key = Name
    changedTag.Value = Value

    setTags([changedTag, ...otherTags])
  }

  const removeGlobalSecondaryIndex = (id: string) => {
    const gsi = gsis.find(g => g.id === id)
    setGsis(gsis.filter(g => g.id !== id))

    if (gsi) {
      removeAttributeType(gsi.KeySchema[0].AttributeName)
      removeAttributeType(gsi.KeySchema[1].AttributeName)
    }
  }

  const changeGSIIndexName = (id: string, IndexName: string) => {
    const otherGSIs = gsis.filter(g => g.id !== id)
    const changedGSI = gsis.find(g => g.id === id)!

    changedGSI.IndexName = IndexName

    setGsis([...otherGSIs, changedGSI])
  }

  const changeGSIPartitionKey = (id: string, value: string) => {
    const otherGSIs = gsis.filter(g => g.id !== id)
    const changedGSI = gsis.find(g => g.id === id)!

    changedGSI.KeySchema[0].AttributeName = value

    setGsis([...otherGSIs, changedGSI])
  }

  const changeGSISortKey = (id: string, value: string) => {
    const otherGSIs = gsis.filter(g => g.id !== id)
    const changedGSI = gsis.find(g => g.id === id)!

    changedGSI.KeySchema[1].AttributeName = value

    setGsis([...otherGSIs, changedGSI])
  }

  const computeParams = () => {
    const GlobalSecondaryIndexes = gsis
      .map(gsi => omit(gsi, "id"))
      .map(g => ({
        ...g,
        Projection: {
          ProjectionType: "ALL",
        },
        BillingMode: "PAY_PER_REQUEST",
      }))
    const params: any = {
      TableName: tableName,
      KeySchema: keyType === "Simple" ? keySchema.slice(0, 1) : keySchema,
      AttributeDefinitions: attributeDefinitions.filter(
        a => a.AttributeName.length > 0
      ),
      BillingMode:
        billingMode === "On-Demand" ? "PAY_PER_REQUEST" : "PROVISIONED",
    }

    if (GlobalSecondaryIndexes.length > 0) {
      params.GlobalSecondaryIndexes = GlobalSecondaryIndexes
    }

    if (billingMode === "Provisioned") {
      params.ProvisionedThroughput = {
        ReadCapacityUnits: rcu,
        WriteCapacityUnits: wcu,
      }
    }

    if (tags.length > 0) {
      params.Tags = tags.map(t => omit(t, "id"))
    }

    return params
  }

  const changeKeyTypee = (keyType: string) => {
    changeKeyType(keyType)
    if (keyType === "Simple") {
      removeAttributeType(keySchema[1].AttributeName)
    }
  }

  return (
    <Flex
      flexWrap="wrap"
      sx={{ maxWidth: "1200px", marginLeft: [0, 0, "-12.5%"] }}
      justifyContent="center"
      margin="auto"
      width={[1, 1, "125%"]}
      mt={32}
    >
      <Flex width={[1, 1, 1 / 2]} p={2} flexDirection="column">
        <Text fontSize={18} fontWeight="bold" my={1}>
          Set Table Name
        </Text>

        <Input
          type="string"
          id="tableName"
          sx={{ height: "40px", borderColor: "hsl(0,0%,80%)" }}
          aria-describedby="dynamodb-table-name"
          value={tableName}
          onChange={(e: any) => {
            setTablename(e.target.value)
          }}
        />
        <Text fontSize={14} color="#999" mt={1}>
          Must be between 3 and 255 characters long
        </Text>

        <Box my={1}>
          <Text fontSize={18} fontWeight="bold" my={1}>
            Primary Key Type
          </Text>
          <Selector
            defaultValue="Simple"
            setSelectedValue={changeKeyTypee}
            options={["Simple", "Composite"]}
          />

          <Text fontSize={14} color="#999" mt={1}>
            Simple - key that is composed of only one attribute.
            <br /> Composite - key that is composed of two attributes. The first
            attribute is the partition key, and the second attribute is the sort
            key
          </Text>
        </Box>

        <Text fontSize={18} fontWeight="bold" my={1}>
          Select Partition Key
        </Text>
        <Flex flexDirection="row">
          <Box width={1 / 2}>
            <Input
              type="string"
              id="partitionKey"
              aria-describedby="dynamodb-partitionKey"
              value={keySchema[0].AttributeName}
              height="40px"
              sx={{ height: "40px", borderColor: "hsl(0,0%,80%)" }}
              onChange={(e: any) => {
                const current = keySchema[0]

                setPartitionKey(e.target.value)

                if (current) {
                  changeAttributeTypeName(current.AttributeName, e.target.value)
                }
              }}
              style={{
                height: "38px",
              }}
            />
          </Box>
          <Box width={1 / 2}>
            <Selector
              defaultValue="String"
              setSelectedValue={e =>
                addOrChangeAttributeType(keySchema[0].AttributeName, e)
              }
              options={keyAttributeTypes}
            />
          </Box>
        </Flex>

        {keyType === "Composite" && (
          <>
            <Text fontSize={18} fontWeight="bold" my={1}>
              Select Sort Key
            </Text>
            <Flex flexDirection="row">
              <Box width={1 / 2}>
                <Input
                  type="string"
                  id="sortKey"
                  aria-describedby="dynamodb-sortKey"
                  value={keySchema[1].AttributeName}
                  onChange={(e: any) => {
                    const current = keySchema[1]

                    if (current) {
                      changeAttributeTypeName(
                        current.AttributeName,
                        e.target.value
                      )
                    }

                    setSortKey(e.target.value)
                  }}
                  sx={{ height: "40px", borderColor: "hsl(0,0%,80%)" }}
                  placeholder="Sort Key Attribute"
                />
              </Box>
              <Box width={1 / 2}>
                <Selector
                  defaultValue="String"
                  setSelectedValue={e =>
                    addOrChangeAttributeType(keySchema[1].AttributeName, e)
                  }
                  options={keyAttributeTypes}
                />
              </Box>
            </Flex>
          </>
        )}

        {gsis.map(gsi => (
          <Flex>
            <Box width={9 / 10}>
              <Text fontSize={18} fontWeight="bold" my={1}>
                Define Index
              </Text>
              <Input
                type="string"
                sx={{ height: "40px", borderColor: "hsl(0,0%,80%)" }}
                id="indexName"
                placeholder="Index Name"
                aria-describedby="dynamodb-index-name"
                value={gsis.find(g => g.id === gsi.id)!.IndexName}
                onChange={(e: any) => {
                  changeGSIIndexName(gsi.id, e.target.value)
                }}
              />
              <Flex flexDirection="row" my={1}>
                <Box width={1 / 2}>
                  <Input
                    type="string"
                    id="IndexpartitionKey"
                    sx={{ height: "40px", borderColor: "hsl(0,0%,80%)" }}
                    aria-describedby="dynamodb-partitionKey"
                    value={
                      gsis.find(g => g.id === gsi.id)!.KeySchema[0]
                        .AttributeName
                    }
                    onChange={(e: any) => {
                      const current = gsis.find(g => g.id === gsi.id)!
                        .KeySchema[0]

                      if (current) {
                        changeAttributeTypeName(
                          current.AttributeName,
                          e.target.value
                        )
                      }

                      changeGSIPartitionKey(gsi.id, e.target.value)
                    }}
                    style={{
                      height: "38px",
                    }}
                    placeholder="Partition Key"
                  />
                </Box>
                <Box width={1 / 2}>
                  <Selector
                    defaultValue="String"
                    setSelectedValue={e =>
                      addOrChangeAttributeType(
                        gsis.find(g => g.id === gsi.id)!.KeySchema[0]
                          .AttributeName,
                        e
                      )
                    }
                    options={keyAttributeTypes}
                  />
                </Box>
              </Flex>
              <Flex flexDirection="row" my={1}>
                <Box width={1 / 2}>
                  <Input
                    type="string"
                    id="IndexpartitionKey"
                    sx={{ height: "40px", borderColor: "hsl(0,0%,80%)" }}
                    aria-describedby="dynamodb-partitionKey"
                    value={
                      gsis.find(g => g.id === gsi.id)!.KeySchema[1]
                        .AttributeName
                    }
                    onChange={(e: any) => {
                      const current = gsis.find(g => g.id === gsi.id)!
                        .KeySchema[1]

                      // Race condition between setState changes....
                      if (current) {
                        changeAttributeTypeName(
                          current.AttributeName,
                          e.target.value
                        )
                      }

                      changeGSISortKey(gsi.id, e.target.value)
                    }}
                    style={{
                      height: "38px",
                    }}
                    placeholder="Sort Key"
                  />
                </Box>
                <Box width={1 / 2}>
                  <Selector
                    defaultValue="String"
                    setSelectedValue={e =>
                      addOrChangeAttributeType(
                        gsis.find(g => g.id === gsi.id)!.KeySchema[1]
                          .AttributeName,
                        e
                      )
                    }
                    options={keyAttributeTypes}
                  />
                </Box>
              </Flex>
            </Box>
            <Box width={1 / 10}>
              <Flex justifyContent="center" alignItems="center" height="100%">
                <TabCloseButton
                  onClick={() => removeGlobalSecondaryIndex(gsi.id)}
                />
              </Flex>
            </Box>
          </Flex>
        ))}

        <Button
          style={{
            marginTop: "5px",
            backgroundColor: "white",
            width: "100%",
            padding: "5px 0",
            color: "black",
            cursor: "pointer",
            // border: "1px solid hsl(0,0%,80%)",
            fontSize: "0.85em",
          }}
          onClick={addGlobalSecondaryIndex}
        >
          + Add Global Secondary Index
        </Button>

        <Text fontSize={18} fontWeight="bold" my={1}>
          Capacity Mode
        </Text>
        <Selector
          defaultValue="On-Demand"
          setSelectedValue={changeBillingMode}
          options={["On-Demand", "Provisioned"]}
        />
        {billingMode === "Provisioned" ? (
          <Text fontSize={14} color="#999" mt={1}>
            Pay for provisioned number of RCU (Read Capacity Units) and WCU
            (Write Capacity Units).{" "}
            <a
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#1B6AFF",
                marginTop: "5px",
                marginBottom: "5px",
              }}
              href="https://dynobase.dev/dynamodb-capacity-modes/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn More
            </a>
          </Text>
        ) : (
          <Text fontSize={14} color="#999" mt={1}>
            Pay for WRU (Write Request Units) and RRU (Read Request Units).{" "}
            <a
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#1B6AFF",
                marginTop: "5px",
                marginBottom: "5px",
              }}
              href="https://dynobase.dev/dynamodb-capacity-modes/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn More
            </a>
          </Text>
        )}
        {billingMode === "Provisioned" && (
          <>
            <Flex flexDirection="row" mt={1}>
              <Box width={1 / 2}>
                <Text fontSize={18} fontWeight="bold" my={1}>
                  Read Capacity Units
                </Text>
                <Input
                  type="number"
                  id="rcu"
                  sx={{ height: "40px", borderColor: "hsl(0,0%,80%)" }}
                  aria-describedby="dynamodb-table-rcu"
                  value={rcu}
                  placeholder="Read Capacity Units"
                  onChange={(e: any) => {
                    setRcu(parseInt(e.target.value.replace(/\D/, ""), 10))
                  }}
                />
              </Box>
              <Box width={1 / 2}>
                <Text fontSize={18} fontWeight="bold" my={1}>
                  Write Capacity Units
                </Text>
                <Input
                  type="number"
                  id="wcu"
                  sx={{ height: "40px", borderColor: "hsl(0,0%,80%)" }}
                  placeholder="Write Capacity Units"
                  aria-describedby="dynamodb-table-wcu"
                  value={wcu}
                  onChange={(e: any) => {
                    setWru(parseInt(e.target.value.replace(/\D/, ""), 10))
                  }}
                />
              </Box>
            </Flex>
            <Text fontSize={14} color="#999" mt={1}>
              Wondering how much that is going to cost?{" "}
              <a
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#1B6AFF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
                href="https://dynobase.dev/dynamodb-pricing-calculator/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Use our pricing calculator
              </a>
            </Text>
          </>
        )}

        {tags.map((tag, index) => (
          <Flex flexDirection="row" mt={1}>
            <Box width={4 / 9}>
              <Input
                type="text"
                id="tag-name"
                aria-describedby={`dynamodb-table-tag-value-${index}`}
                value={tag.Key}
                sx={{ height: "40px", borderColor: "hsl(0,0%,80%)" }}
                placeholder="Tag Name"
                onChange={(e: any) => {
                  changeTag(tag.id, e.target.value, tag.Value)
                }}
              />
            </Box>
            <Box width={4 / 9}>
              <Input
                type="text"
                id="tag-value"
                placeholder="Tag Value"
                aria-describedby={`dynamodb-table-tag-value-${index}`}
                value={tag.Value}
                sx={{ height: "40px", borderColor: "hsl(0,0%,80%)" }}
                onChange={(e: any) => {
                  changeTag(tag.id, tag.Key, e.target.value)
                }}
              />
            </Box>
            <Box width={1 / 9}>
              <Flex justifyContent="center" alignItems="center" height="100%">
                <TabCloseButton onClick={() => removeTag(tag)} />
              </Flex>
            </Box>
          </Flex>
        ))}

        <Button
          style={{
            marginTop: "5px",
            backgroundColor: "white",
            width: "100%",
            padding: "10px 0",
            color: "black",
            cursor: "pointer",
            // border: "1px solid hsl(0,0%,80%)",
            fontSize: "0.85em",
          }}
          onClick={() => addTag()}
        >
          + Add Tag
        </Button>

        {error && (
          <Text
            fontSize={14}
            color="red"
            mt={1}
            padding={10}
            backgroundColor="rgba(255,0,0,0.05)"
          >
            {error}
          </Text>
        )}
      </Flex>
      <Flex width={[1, 1, 1 / 2]} p={2} sx={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            zIndex: 10000,
          }}
        >
          <CopyToClipboard
            text={JSON.stringify(computeParams(), null, 2)}
            // onCopy={() => showCopiedCode(true)}
          >
            <Button
              style={{
                color: "rgb(24, 144, 255)",
                fontWeight: 600,
                boxShadow:
                  "rgba(136, 144, 195, 0.2) 0px 2px 4px 0px, rgba(37, 44, 97, 0.35) 0px 5px 15px 0px",
                outline: 0,
                cursor: "pointer",
                backgroundColor: "white",
              }}
            >
              Copy to Clipboard
            </Button>
          </CopyToClipboard>
        </div>

        <Editor
          value={JSON.stringify(computeParams(), null, 2)}
          highlight={code =>
            highlight(code, languages.javascript, "javascript")
          }
          style={{
            fontFamily: "hack, sans-serif",
            fontSize: 14,
            padding: "18px",
            // border: "solid 0.5px rgb(213, 213, 213)",
            borderRadius: "5px",
            outline: 0,
            backgroundColor: "#333",
            marginBottom: "10px",
            minHeight: "540px",
            color: "rgb(80, 250, 123)",
            width: "100%",
          }}
        />
      </Flex>
    </Flex>
  )
}

export default NewTable
