import React from "react"
import { FooterWithCTA, SEO, Layout } from "@components"
import TableBuilder from "@components/tableCreator/App"
import FAQ from "@components/pages/table-schema-design-tool/FAQ/index.js"
import { Box } from "rebass"
import { Container } from "@components"
import { InternalLink } from "@fragments"
import { feature } from "@constants/urls.js"

const TableBuilderPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title="DynamoDB Table Schema Design Tool [Free to Use]"
          description="Use this free tool to help you design your DynamoDB table schema visually and generate JSON that can then be used to create the DynamoDB table."
          canonical="https://dynobase.dev/dynamodb-table-schema-design-tool/"
        />
        <Container>
          <Box mt={62}>
            <h1 style={{ letterSpacing: -1 }}>
              DynamoDB Table Schema Design Tool
            </h1>
            <p style={{ marginBottom: "0.8em" }}>
              DynamoDB JSON that is used to create DynamoDB table requires you
              to understand its complicated format. This tool solve this problem
              by helping you design the table definition visually. The resulting
              DynamoDB JSON output can be then used to{" "}
              <InternalLink to={feature.createTable}>
                create DynamoDB table
              </InternalLink>{" "}
              via DynamoDB CLI, SDK, CloudFormation, Serverless Framework etc.
            </p>
          </Box>
          <TableBuilder />
          <FAQ />
        </Container>
        <FooterWithCTA noMarginTop />
      </Layout>
    </>
  )
}

export default TableBuilderPage
