import React from "react"
import data from "./data"
import { Box } from "rebass"
import FAQJsonLD from "@components/FAQJsonLD"

const FAQ = () => (
  <Box mt={52}>
    <h2 style={{ marginBottom: "32px" }}>Frequently Asked Questions</h2>
    {data.map(faq => {
      return (
        <Box>
          <h3>{faq.question}</h3>
          <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
        </Box>
      )
    })}
    <FAQJsonLD questions={data} />
  </Box>
)

export default FAQ
