import React from "react"
import Select from "react-select"

interface SelectorProps {
  defaultValue: string
  options: string[]
  setSelectedValue: (value: any) => void
}

const Selector: React.FC<SelectorProps> = (props: SelectorProps) => {
  const optionsMapped = props.options.map(item => {
    return { value: item, label: item }
  })
  const defaultValue = optionsMapped.find(profile => profile.value)

  return (
    <Select
      options={optionsMapped}
      defaultValue={defaultValue}
      onChange={(e: any) => props.setSelectedValue(e.value)}
      styles={{
        control: provided => ({
          ...provided,
          height: "38px",
        }),
      }}
      theme={theme => ({
        height: "36px",
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#f8f8f8",
          primary50: "#f8f8f8",
          primary: "#d5d5d5",
        },
      })}
    />
  )
}

export default Selector
