export default [
  {
    question: "What are the ways to create DynamoDB table in AWS?",
    answer:
      "Most popular ways include AWS Console, AWS SDKs, AWS CLI, CloudFormation, Terraform, Serverless Framework, AWS SAM (Serverless Application Model) and of course Dynobase. The tool on this page <a href='https://dynobase.dev/dynamodb-create-table/'>is also available inside Dynobase</a>.",
  },
  {
    question: "What should I do with this DynamoDB JSON?",
    answer:
      "When using SDK or CLI, you need to use it as the table definition parameter when calling CreateTable operation. When using CloudFormation, SAM or Serverless Framework, paste this into your 'infrastructure-as-code' DynamoDB::Table.Properties value",
  },
  {
    question:
      "What are all the keys mentioned in the creator? Partition key, sort key, composite key and so on...",
    answer:
      "You can read about them in this <a href='https://dynobase.dev/dynamodb-keys/'>article about DynamoDB Keys.</a>.",
  },
  {
    question: "Are DynamoDB table names unique?",
    answer: "Yes. They need to be unique per account per region.",
  },
  {
    question: "What are DynamoDB table/attribute/GSI naming conventions?",
    answer:
      "There aren't any conventions around the naming of tables, attributes, or GSIs. It is worth mentioning that table names are UTF-8 encoded, case-sensitive, and should be between 3 and 255 characters long. They can also include special characters like - (dash), _ (underscore), and . (dot).",
  },
  {
    question: "Are DynamoDB tables schemaless?",
    answer:
      "Yes. When creating a table in DynamoDB, you don't have to define any other attributes besides ones that are used by primary key and global secondary keys. ",
  },
  {
    question: "Are DynamoDB tables encrypted at REST?",
    answer:
      "Yes. All user data stored using DynamoDB is encrypted. By default, AWS owned CMK (customer master key) is used to achieve that at no additional charge. Alternatively, you can choose 'AWS managed CMK' and 'Customer managed CMK' where additional charges apply, but you have control over the keys.",
  },
  {
    question: "Can you change the table name in DynamoDB?",
    answer:
      "No, it's not possible. You can change table GSIs, <a href='https://dynobase.dev/dynamodb-capacity-modes/'>capacity modes</a>, and backup settings, but it's impossible to change table name or primary index after it's created. The only option here is to create a new table with other name and copy data from one table to another.",
  },
  {
    question: "How do I see DynamoDB table data?",
    answer:
      "After you've entered some data to the table, it is likely that you'll need to debug it. You can inspect table contents using AWS Console or <a href='https://dynobase.dev/'>Dynobase</a>.",
  },
]
